/* Parent container to hold the scrolling content */
.marquee-container {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    height: 200px; /* Adjust as per your image height */
    position: relative;
  }
  
  /* Content with two images aligned side by side */
  .marquee-content {
    display: flex; /* Ensures images line up horizontally */
    background-color: #f7f7f7;
    animation: scroll 20s linear infinite; /* Continuous animation */
  }
  
  .marquee-content img {
    height: 100%; /* Full height of the container */
    width: auto; /* Maintain aspect ratio */
  }
  
  /* Smooth scrolling animation */
  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  