/* Animation part */
.footer-animation-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 200px;
    /* background-color: #f8f8f8; Adjust background color as needed */
  }
  
  .footer-animation {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  /* Moving background for the landmarks */
  .moving-background {
    /* color: red; */
    position: absolute;
    top: 0;
    left: 0;
    width: 300%; /* Make the image wide enough for smooth animation */
    height: 100%;
    animation: moveBackground 10s linear infinite;
  }
  
  /* Airplane will remain static */
  .static-airplane {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10; /* Ensure it stays above the moving background */
    /* width: 100px; Adjust as per the size needed */
  }
  
  /* Keyframe animation to move background from right to left */
  @keyframes moveBackground {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    /* .static-airplane {
      width: 80px; Adjust the airplane size for smaller screens
    } */
  }
  
  /* @media (max-width: 480px) {
    .static-airplane {
      width: 60px; Even smaller airplane on tiny screens
    }
  } */
  