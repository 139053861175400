body {
  font-family: 'Poppins', sans-serif;
  font-weight: 400; /* Regular */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.bold-text {
  font-weight: 700; /* Bold */
}

.light-text {
  font-weight: 300; /* Light */
}

.extra-bold-text {
  font-weight: 800; /* Extra Bold */
}
