.study-abroad-loan-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 100px;
    background-color: #2574fc1c ;
  }
  
  .abroad-heading{
    font-size: 2.7rem;
  }

  .loan-info-container {
    flex: 1;
    margin-right: 40px;
  }
  
  .loan-features {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px;
  }
  
  .feature {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
  }
  
  .feature .icon {
    margin-right: 10px;
    color: #4CAF50;
    font-weight: bold;
  }
  
  .cta-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 30px;
  }
  
  .btn {
    /* padding: 12px 24px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease; */

    
        padding: 15px 25px;
        font-size: 1rem;
        border-radius: 25px;
        cursor: pointer;
        border: none;
  }
  
  .btn.best-service {
    background-color: #7367F0;
    color: #fff;
    margin-right: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .btn.best-service:hover {
    background-color: #5e4dc5;
    transform: scale(1.05);
  }
  
  .btn.lenders {
    background-color: #fff;
    color: #7367F0;
    border: 1px solid #7367F0;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .btn.lenders:hover {
    background-color: #f0f0f0;
    transform: scale(1.05);
  }
  
  .loan-steps-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    flex: 1;
  }
  
  .step {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .step-number {
    font-size: 24px;
    font-weight: 700;
    color: #7367F0;
    margin-bottom: 10px;
  }
  
  .step-title {
    font-size: 16px;
    font-weight: 500;
  }
  
  .loan-image-container {
    flex: 1;
  }
  
  .loan-image-container img {
    max-width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media (max-width: 1024px) {
    .study-abroad-loan-container {
      flex-direction: column;
      padding: 30px;
    }
  
    .loan-info-container {
      margin-right: 0;
      margin-bottom: 30px;
    }
  
    .loan-steps-container {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .loan-image-container {
      margin-top: 30px;
    }
  }
  
  @media (max-width: 768px) {
    .loan-features {
      grid-template-columns: 1fr;
    }
  
    .cta-container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .btn.best-service {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .loan-steps-container {
      grid-template-columns: 1fr;
    }
  }