/* General Styling */
.insurance-page {
    font-family: 'Poppins', sans-serif;
    color: #333;
    background-color: #f7f9fc;
  }
  
  h1, h2 {
    font-weight: 600;
  }
  
  p {
    margin: 0;
    line-height: 1.6;
  }
  
  /* Header Section */
  .insurance-header {
    margin: 20px 10px;
    text-align: center;
    padding: 30px 20px;
    /* background: linear-gradient(to right, #005c99, #007acc); */
    background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
    color: white;
    border-radius: 10px;
  }
  
  .insurance-header h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .insurance-header p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .cta-button {
    background-color: #ff6f00;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #e65c00;
  }
  
  /* Insurance Types Section */
  .insurance-section {
    padding: 40px 20px;
    text-align: center;
  }
  
  .insurance-section h2 {
    font-size: 2rem;
    margin-bottom: 30px;
  }
  
  .insurance-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .insurance-card {
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .insurance-icon {
    font-size: 3rem;
    color: #2575fc;
    margin-bottom: 15px;
  }
  
  .insurance-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .insurance-card p {
    font-size: 1rem;
    color: #666;
  }
  
  /* Why Choose Us Section */
  .why-choose-us {
    background-color: #eef4fc;
    padding: 40px 20px;
    text-align: center;
    border-radius: 10px;
    margin: 20px 10px;
  }
  
  .reasons-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .reason-card {
    background: white;
    padding: 20px;
    border: 1px solid #d9e7f7;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* Footer Section */
  .insurance-footer {
    text-align: center;
    padding: 15px;
    background: #005c99;
    color: white;
  }
  