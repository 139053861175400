/* Header Styles */
.header {
  background: linear-gradient(112deg, rgb(255 255 255 / 0%) 0%, rgb(130 39 247) 100%);
  padding: 10px 20px;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 220px;
  margin-right: 20px;
}

.menu-toggle {
  display: none;
}

.menu-icon {
  font-size: 1.5rem;
  cursor: pointer;
  display: none;
}

.to-make-pointer, .dropdown-icon{
  cursor: pointer;
}

.header nav ul {
  list-style-type: none;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.header nav ul li {
  position: relative;
  margin-right: 20px;
}

.header nav ul li a {
  text-decoration: none;
  color: #FFFFFF;
  display: flex;
  align-items: center; /* Align icon and text vertically */
}

.dropdown-icon {
  margin-left: 5px; /* Space between text and icon */
  fill: #fff;
  transition: transform 0.3s ease;
}

.dropdown:hover .dropdown-icon {
  transform: rotate(180deg); /* Rotate the icon on hover */
}

.header ul li a:hover {
  color: #000000;
}

/* Dropdown Styles */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #bb8afc;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 25px;
  left: 0;
  border-radius: 8px;
  overflow: hidden;
}

.dropdown-content li {
  padding: 5px 10px;
}

.dropdown-content li a {
  color: #000;
  text-decoration: none;
}



.dropdown:hover .dropdown-content {
  display: block;
}

/* Media Queries for Responsive Design */
@media only screen and (max-width: 768px) {
  .logo {
      width: 150px;
  }

  .header nav ul {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 30px;
      left: 0;
      width: 100%;
      background-color: #7e60d1d9;
  }

  .header nav ul.open {
      display: flex;
      z-index: 100;
      align-items: center;
  }

  .header nav ul li {
      margin: 10px 0;
  }

  .menu-icon {
      display: block;
  }
}
