/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Transparent black background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  /* Modal Content */
  .modal-content {
    background: linear-gradient(145deg, #e6dff1, #f4f0fa); /* Glossy light background */
    border-radius: 16px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15); /* Soft shadow for premium feel */
    padding: 2rem;
    width: 500px;
    max-width: 90%;
    position: relative;
    animation: fadeIn 0.3s ease-in-out;
    transition: all 0.3s ease;
  }
  
  /* Close Button */
  .close-btn {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 24px;
    border: none;
    background: transparent;
    color: #7e57c2; /* Purple from your page */
    cursor: pointer;
  }
  
  /* Form Inputs */
  input,
  textarea {
    width: 100%;
    margin: 8px 0;
    padding: 12px;
    border-radius: 10px;
    border: 1px solid #ccc;
    background: #f8f7fc;
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.05); /* Subtle inner shadow */
    font-size: 16px;
  }
  
  /* Submit Button */
  .submit-btn {
    width: 100%;
    background: linear-gradient(90deg, #7e57c2, #ab47bc); /* Purple gradient */
    color: white;
    padding: 12px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
  }
  
  .submit-btn:hover {
    background: linear-gradient(90deg, #ab47bc, #7e57c2); /* Reverse gradient on hover */
  }
  
  .submit-btn:disabled {
    background: #ddd;
    cursor: not-allowed;
  }
  
  /* Status Message */
  .status-message {
    margin-top: 10px;
    color: #4caf50; /* Green success message */
    text-align: center;
  }
  
  /* Fade-in Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  

  .autosuggest-container {
    position: relative;
  }
  
  .autosuggest-suggestions-container {
    position: absolute;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
  }
  
  .autosuggest-suggestions-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .autosuggest-suggestion {
    padding: 10px;
    cursor: pointer;
  }
  
  .autosuggest-suggestion--highlighted {
    background-color: #ddd;
  }

  /* captcha */

  .recaptcha-container {
    margin: 20px 0; /* Space above and below the reCAPTCHA */
    display: flex;
    justify-content: center; /* Center the reCAPTCHA */
  }
  
  /* Additional styles for your modal and other components can go here */
  

  