.faq-section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.faq-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

.faq-header h2 {
    font-size: 2rem;
    font-weight: 700;
    color: #333;
    text-align: center;
}

.faq-line {
    width: 60px;
    height: 3px;
    background: linear-gradient(to right, #8c52ff, #333);
    margin: 0 20px;
}

.faq-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.faq-item {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    transition: all 0.3s ease;
}

.faq-item.active .faq-answer {
    max-height: 500px;
    opacity: 1;
    padding-top: 10px;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;
}

.faq-question p {
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
    margin: 0;
}

.faq-icon {
    font-size: 1.5rem;
    color: #8c52ff;
}

.faq-answer {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.4s ease;
    padding-right: 20px;
}

.faq-answer p {
    font-size: 1rem;
    color: #666;
}

@media (max-width: 768px) {
    .faq-header h2 {
        font-size: 1.5rem;
    }

    .faq-question p {
        font-size: 1rem;
    }

    .faq-answer p {
        font-size: 0.9rem;
    }
}