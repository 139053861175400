.country-heading{
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2rem;
}

.countries-section {
    padding: 2rem;
    text-align: center;
}

.countries-grid {
    display: flex;
    flex-wrap: wrap;
    
    justify-content: center;
    gap: 1.5rem;
}

.country-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 250px;
}

.country-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.highlight {
    color: red;
    font-weight: bold;
}

