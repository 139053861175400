

/* Testimonials.css */
/* 
.testimonial-section {
  background-color: #f9f9f9;
  padding: 50px 20px;
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.testimonial-header h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 10px;
}

.testimonial-header p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 40px;
}

.testimonial-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.testimonial-card {
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 30%;
  margin: 10px 0;
  padding: 20px;
  transition: transform 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-10px);
}

.testimonial-content {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
  position: relative;
}

.quote-icon {
  font-size: 2rem;
  color: #ff5e57;
  position: absolute;
  top: -20px;
  left: 0;
}

.testimonial-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-footer img {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-right: 10px;
}

.testimonial-footer span {
  font-size: 1rem;
  color: #333;
}

@media (max-width: 768px) {
  .testimonial-cards {
    flex-direction: column;
    align-items: center;
  }

  .testimonial-card {
    width: 100%;
  }
} */

/* Testimonials.css */
.testimonial-section {
  background-color: #f9f9f9;
  padding: 50px 20px;
  text-align: center;
}

.testimonial-header h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 10px;
}

.testimonial-header p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 40px;
}

.testimonial-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.testimonial-card {
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 30%;
  margin: 10px 0;
  padding: 20px;
  transition: transform 0.3s ease, max-height 0.5s ease; /* Smooth transition */
  max-height: 200px; /* Show less content initially */
  overflow: hidden; /* Hide overflow */
}

.testimonial-card:hover {
  transform: translateY(-10px);
  max-height: 600px; /* Expand to show full content on hover */
}

.testimonial-content {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
  position: relative;
}

.quote-icon {
  font-size: 2rem;
  color: #ff5e57;
  position: absolute;
  top: -20px;
  left: 0;
}

.testimonial-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-footer img {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-right: 10px;
}

.testimonial-footer span {
  font-size: 1rem;
  color: #333;
}

/* Responsive styles */
@media (max-width: 768px) {
  .testimonial-cards {
    flex-direction: column;
    align-items: center;
  }

  .testimonial-card {
    width: 100%;
  }
}
