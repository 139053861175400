* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.eligibility-wrapper {
    margin: 0 auto;
    background: linear-gradient(to right, #8c52ff, #ffffff, #ffffff);
}

.eligibility-box {
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.eligibility-title {
    font-size: 2rem;
    color: #12284C;
    margin-bottom: 10px;
}

.eligibility-description {
    font-size: 1.125rem;
    color: #444;
    text-align: center;
    margin-bottom: 30px;
}

.eligibility-structure {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;
}

.eligibility-info {
    flex: 1;
    min-width: 300px;
    max-width: 600px;
}

.checklist-arrow {
    font-size: 1.5rem;
    padding-right: 10px;
}

.eligibility-checklist {
    list-style: none;
    padding: 0 0 0 120px;
}

.eligibility-checklist ul {
    list-style: none;
    padding-left: 30px;
}

.checklist-item {
    padding-bottom: 20px;
}

.checklist-subitem {
    font-size: 0.95rem;
}

.eligibility-image {
    flex: 1;
    min-width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eligibility-image img {
    width: 100%;
    max-width: 400px;
}

/* Existing styles remain unchanged */

/* Responsive Design */
@media (max-width: 768px) {
    .eligibility-structure {
        flex-direction: column; /* Stack items vertically */
        align-items: center;
    }

    .eligibility-image {
        order: -1; /* Move image above the text but below the heading */
        width: 100%; /* Ensure full width */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .eligibility-image img {
        max-width: 350px;
        width: 100%; /* Make image responsive */
    }

    .eligibility-title {
        font-size: 1.6rem;
    }

    .eligibility-description {
        font-size: 0.95rem;
    }
}

@media (max-width: 480px) {
    .eligibility-box {
        padding: 30px 15px;
    }

    .eligibility-title {
        font-size: 1.5rem;
    }

    .eligibility-description {
        font-size: 0.875rem;
    }

    .eligibility-image img {
        max-width: 300px;
    }

    .eligibility-checklist > li {
        font-size: 0.95rem;
    }
}
