.contact-section {
    padding: 50px 50px;
    text-align: center;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.8) 30%, rgba(167, 149, 255, 0.788) 100%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


.contact-header h2 {
    font-size: 2.5rem;
    color: #7e57c2; /* Purple from your page */
    margin-bottom: 10px;
}

.contact-header p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 40px;
}

.contact-container {
    display: flex;
    flex-direction: row; /* Default layout for larger screens */
    align-items: flex-start;
    justify-content: center;
    max-width: 100%;
    margin: 0 auto;
    background: linear-gradient(145deg, #e6dff1, #f4f0fa); /* Glossy background */
    border-radius: 16px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15); /* Soft shadow */
    padding: 30px;
}

.contact-image {
    flex: 1; /* Take up 50% of the space */
    margin-right: 20px; /* Space between image and form */
}

.contact-image img {
    margin-top: 40px;
    max-width: 80%;
    height: auto;
    border-radius: 15px;
}

.contact-form {
    flex: 1; /* Take up the other 50% */
    display: flex;
    flex-direction: column;
    width: 100%;
}

.contact-form label {
    margin-bottom: 5px;
    text-align: left;
    color: #333;
    font-weight: bold;
}

.contact-form input,
.contact-form select,
.contact-form textarea {
    width: 100%;
    margin: 8px 0;
    padding: 12px;
    border-radius: 10px;
    border: 1px solid #ccc;
    background: #f8f7fc; /* Light background for inputs */
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.05); /* Subtle inner shadow */
    font-size: 16px;
    transition: border-color 0.3s ease;
}

.contact-form input:focus,
.contact-form select:focus,
.contact-form textarea:focus {
    outline: none;
    border-color: #7e57c2; /* Purple focus color */
    box-shadow: 0 0 5px rgba(126, 87, 194, 0.5); /* Soft glow */
}

.submit-btn {
    width: 100%;
    background: linear-gradient(90deg, #7e57c2, #ab47bc); /* Purple gradient */
    color: white;
    padding: 12px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 18px;
    transition: background 0.3s ease;
}

.submit-btn:hover {
    background: linear-gradient(90deg, #ab47bc, #7e57c2); /* Reverse gradient on hover */
}

@media (max-width: 768px) {
    .contact-container {
        flex-direction: column; /* Stack image on top of form */
    }

    .contact-image {
        margin-right: 0; /* Remove right margin */
        margin-bottom: 20px; /* Add bottom margin */
    }

    .contact-form {
        width: 100%; /* Full width for form */
    }
}
