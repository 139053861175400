.education-loan-container {
  padding: 3rem;
  background: linear-gradient(to bottom, #f3f0ff, #ffffff);
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  /* max-width: 1200px; */
  /* margin: auto; */
}

.header-section {
  margin-bottom: 2rem;
}

.quote {
  font-size: 2.2rem;
  font-weight: bold;
  color: #4a4a8e;
  line-height: 1.5;
}

.content-section {
  margin-bottom: 2.5rem;
}

.description {
  font-size: 1.4rem;
  color: #6c6c8c;
  margin-bottom: 2rem;
}

.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.feature1 {
  background: #ffffff;
  border: 2px solid #e0e0ff;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.feature1 h3 {
  font-size: 1.4rem;
  font-weight: bold;
  color: #4a4a8e;
  margin-bottom: 1rem;
}

.feature1 p {
  font-size: 1.1rem;
  color: #5a5a7e;
}

.cta-section {
  text-align: center;
}

.cta-button {
  font-size: 1.1rem;
  font-weight: bold;
  color: #ffffff;
  background: #4a4a8e;
  border: none;
  border-radius: 8px;
  padding: 1rem 2rem;
  cursor: pointer;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
}

.cta-button:hover {
  background: #6c6c8c;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .education-loan-container {
    padding: 2.5rem;
  }

  .quote {
    font-size: 1.8rem;
  }

  .description {
    font-size: 1.2rem;
  }

  .feature h3 {
    font-size: 1.2rem;
  }

  .feature p {
    font-size: 1rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 0.8rem 1.6rem;
  }
}

@media (max-width: 768px) {
  .education-loan-container {
    padding: 2rem;
  }

  .quote {
    font-size: 1.6rem;
  }

  .description {
    font-size: 1.1rem;
  }

  .feature h3 {
    font-size: 1.1rem;
  }

  .feature p {
    font-size: 0.95rem;
  }

  .cta-button {
    font-size: 0.95rem;
    padding: 0.7rem 1.4rem;
  }
}

@media (max-width: 480px) {
  .education-loan-container {
    padding: 1.5rem;
  }

  .quote {
    font-size: 1.4rem;
  }

  .description {
    font-size: 1rem;
  }

  .feature h3 {
    font-size: 1rem;
  }

  .feature p {
    font-size: 0.9rem;
  }

  .cta-button {
    font-size: 0.85rem;
    padding: 0.6rem 1.2rem;
  }
}