/* EMICalculator.css */
.emi-calculator-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3rem;
    background: linear-gradient(to bottom, #f3f0ff, #ffffff);
    border-radius: 16px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .text-section {
    flex: 1 1 50%;
    padding: 1rem;
    text-align: center;
  }
  
  .title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #1a1a1b;
    margin-bottom: 1.5rem;
  }
  
  .description {
    font-size: 1.4rem;
    color: #2d2d2e;
    margin-bottom: 2rem;
  }
  
  .form-section {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
  }
  
  .form-group label {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
    color: #333;
  }
  
  .form-label-icon {
    font-size: 1.2rem;
    margin-left: 0.5rem;
    color: #666;
    cursor: help;
  }
  
  .form-group input {
    flex: 1;
    padding: 0.8rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .calculate-btn {
    width: 100%;
    padding: 1rem;
    font-size: 1.2rem;
    background-color: #8c52ff;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .calculate-btn:hover {
    background-color: #7b40e0;
  }
  
  .calculate-btn:active {
    background-color: #673ab7;
  }
  
  .emi-result {
    margin-top: 2rem;
    font-size: 1.2rem;
    color: #333;
  }
  
  .emi-result h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .emi-result p {
    margin: 0.5rem 0;
  }
  
  @media (max-width: 768px) {
    .emi-calculator-container {
      padding: 2rem;
    }
  
    .text-section {
      flex: 1 1 100%;
    }
  
    .title {
      font-size: 2rem;
    }
  
    .description {
      font-size: 1.2rem;
    }
  
    .form-group label {
      font-size: 1rem;
    }
  
    .form-label-icon {
      font-size: 1rem;
    }
  
    .form-group input {
      font-size: 0.9rem;
    }
  
    .calculate-btn {
      font-size: 1rem;
    }
  
    .emi-result {
      font-size: 1rem;
    }
  
    .emi-result h3 {
      font-size: 1.2rem;
    }
  }