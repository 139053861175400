.footer {
    background-color: #f7f7f7;
    padding: 40px 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
}

.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-contact {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 20px;
}

.contact-item {
    display: flex;
    align-items: center;
}

.social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-icons img{
    width: 1.7rem;
    /* margin: 0 10px; */
}

.footer-main {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 20px;
}

.footer-section {
    flex: 1;
    margin: 20px;
}

.footer-logo {
    width: 150px;
    margin-bottom: 15px;
}

.footer-section h3 {
    font-size: 18px;
    margin-bottom: 15px;
    color: #000;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 10px;
}

.footer-social {
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-icons a {
    color: #6c63ff;
    margin: 0 10px;
    font-size: 24px;
}

@media (max-width: 768px) {
    .footer-main {
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        width: 100%;
        text-align: center;
        margin: 10px 0;
    }

    .footer-social {
        flex-direction: column;
    }

    .social-icons a {
        margin: 10px;
    }
}