/* LoanEligibility.css */
.loan-eligibility-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 3rem;
    background: linear-gradient(to bottom, #f3f0ff, #ffffff);
    border-radius: 16px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .text-section {
    flex: 1 1 50%;
    padding: 1rem;
  }
  
  .title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #1a1a1b;
    margin-bottom: 1.5rem;
  }
  
  .description {
    font-size: 1.4rem;
    color: #2d2d2e;
    margin-bottom: 2rem;
  }
  
  .features {
    list-style: none;
    padding: 0;
  }
  
  .features li {
    font-size: 1.2rem;
    color: #8c52ff;
    margin-bottom: 1.2rem;
    display: flex;
    align-items: center;
  }
  
  .features li .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: #e0e0ff;
    border-radius: 50%;
    margin-right: 1rem;
  }
  
  .features li .icon {
    font-size: 1.5rem;
    color: #4a4a8e;
  }
  
  .image-section {
    flex: 1 1 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .image-circle {
    width: 350px;
    height: 350px;
    background: #ffffff;
    border: 2px solid #e0e0ff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .image {
    width: 100%;
    height: auto;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .loan-eligibility-container {
      padding: 2rem;
    }
  
    .title {
      font-size: 2rem;
    }
  
    .description {
      font-size: 1.2rem;
    }
  
    .features li {
      font-size: 1rem;
    }
  
    .features li .icon-container {
      width: 40px;
      height: 40px;
      margin-right: 0.8rem;
    }
  
    .features li .icon {
      font-size: 1.2rem;
    }
  
    .image-circle {
      width: 300px;
      height: 300px;
    }
  }
  
  @media (max-width: 768px) {
    .loan-eligibility-container {
      flex-direction: column;
      text-align: center;
    }
  
    .text-section {
      flex: 1 1 100%;
      padding: 1rem 0;
    }
  
    .image-section {
      flex: 1 1 100%;
      margin-top: 2rem;
    }
  
    .image-circle {
      width: 250px;
      height: 250px;
    }
  }
  
  @media (max-width: 480px) {
    .title {
      font-size: 1.8rem;
    }
  
    .description {
      font-size: 1.1rem;
    }
  
    .features li {
      font-size: 0.9rem;
    }
  
    .features li .icon-container {
      width: 35px;
      height: 35px;
      margin-right: 0.6rem;
    }
  
    .features li .icon {
      font-size: 1rem;
    }
  
    .image-circle {
      width: 200px;
      height: 200px;
    }
  }