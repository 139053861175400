.main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  height: 90vh;
  background: linear-gradient(to right, #8c52ff, #ffffff, #ffffff);
  margin: 0 auto;
}

.landing-p {
  font-size: 1.1rem;
  color: #000000c5;
  margin-bottom: 20px;
}

.text-continer {
  display: flex;
  flex-direction: column;
}

.text-continer h1 {
  font-size: 2.7rem;
  font-weight: bold;
  word-spacing: 0px;
}


.text-content h1 {
  font-size: 3.5rem;
  font-weight: bold;
  text-align: justify;
}

.buttons {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 3rem;
}

.buttons button {
  padding: 15px 25px;
  font-size: 1rem;
  border-radius: 25px;
  cursor: pointer;
}

.talk-to-counsellors {
  background-color: #edeaea;
  font-weight: 600;
  border: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.talk-to-counsellors:hover {
  background-color: #ffffff;
  transform: scale(1.05);
}

.apply-now {
  background-color: #8c52ff;
  color: #fff;
  border: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.apply-now:hover {
  background-color: #a065ff;
  color: #fff;
  transform: scale(1.05);
}

.googleReviews {
  display: flex;
  justify-content: start;
  gap: 6.7rem;
  align-items: center;
  padding: 20px 0;
}

.googleReviews h3 {
  font-weight: 700;
}

.google-star {
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
}

.googleIcon img {
  display: flex;
  align-items: center;
  justify-content: center;
}


.image-content {
  width: 50%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}

.landing-img {
  width: 80%;
}

.upStar {
  margin: 0;
}

/* Add this to your CSS file */
.star {
  display: inline-block;
  position: relative;
  font-size: 1.5em;
  color: #FFD700;
  /* Gold color for stars */
}

.star::before {
  content: '★★★★★';
  opacity: 0.3;
  /* Light color for the background stars */
}

.star::after {
  content: '★★★★★';
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  /* Adjust this to represent 4.9 stars */
  overflow: hidden;
  color: #FFD700;
  /* Gold color for stars */
}

/* media query */
/* Existing styles remain unchanged */

@media (max-width: 480px) {
  .main-container {
    padding: 30px 15px;
    height: auto; /* Allow content to adjust based on size */
    flex-direction: column; /* Stack items vertically */
  }

  .image-content {
    order: -1; /* Move image above content */
    width: 100%; /* Ensure full width */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .landing-img {
    width: 100%;
  }

  .text-continer {
    text-align: center; /* Center text for better appearance */
  }

  .buttons {
    gap: 1rem;
    flex-direction: column; /* Stack buttons vertically */
    align-items: center;
  }

  .googleReviews {
    flex-direction: column; /* Stack achievements vertically */
    align-items: center;
  }

  .googleReviews h3 {
    text-align: center; /* Center title */
  }
}

@media (max-width: 768px) {
  .main-container {
    align-items: center;
    padding: 40px 20px;
    height: auto; /* Allow content to adjust based on size */
    flex-direction: column; /* Stack items vertically */
  }

  .image-content {
    order: -1; /* Move image above content */
    width: 100%; /* Ensure full width */
  }

  .landing-img {
    width: 100%;
  }
}
