.loan-stats-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
  padding: 10px 10px;
  color: #fff;
  flex-wrap: wrap;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease;
}

.loan-stat {
  flex: 1;
  min-width: 200px;
  text-align: center;
  margin: 10px;
  padding: 5px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  transition: all 0.3s ease;
}



.loan-stat h3 {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 10px;
  opacity: 0.8;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.loan-stat p {
  font-size: 1.8rem;
  font-weight: 700;
  background: linear-gradient(to right, #f0f9ff, #cbebff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Responsive styles */
@media (max-width: 768px) {
  .loan-stats-container {
    flex-direction: column;
    align-items: stretch;
  }

  .loan-stat {
    text-align: center;
    width: 100%;
    margin: 10px 0;
  }
}

@media (max-width: 480px) {
  .loan-stat h3 {
    font-size: 0.9rem;
  }

  .loan-stat p {
    font-size: 1.5rem;
  }
}
