.whatsappButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: #25D366; /* WhatsApp green color */
    border: none;
    border-radius: 25px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.whatsappButton:hover {
    background-color: #1DA851; /* Darker green on hover */
}

.whatsapp-ico {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}