@media only screen and (min-width: 991px) {
    .LoanPartners_loanPartners {
        padding: 20px 6.3rem;
    }
}

@media only screen and (min-width: 1280px) {
    .LoanPartners_loanPartners {
        padding: 20px 6.3rem;
    }
}

.LoanPartners_loanPartners .LoanPartners_heading h2 {
    color: #192b45;
    font-size: 2rem;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.4px;
}

@media only screen and (min-width: 991px) {
    .LoanPartners_loanPartners .LoanPartners_heading h2 {
        font-size: 2rem;
    }
}

@media only screen and (min-width: 1280px) {
    .LoanPartners_loanPartners .LoanPartners_heading h2 {
        margin-bottom: 1px;
    }
}

.LoanPartners_loanPartners .LoanPartners_heading p {
    color: #4e5867;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 150%;
}

@media only screen and (min-width: 991px) {
    .LoanPartners_loanPartners .LoanPartners_heading p {
        font-size: 1.2rem;
    }
}

.LoanPartners_loanPartners .LoanPartners_partnersCards {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;
    margin-top: 24px;
}

@media only screen and (min-width: 768px) {
    .LoanPartners_loanPartners .LoanPartners_partnersCards {
        justify-content: center;
        align-items: center;
        margin-top: 32px;
    }
}

@media only screen and (min-width: 991px) {
    .LoanPartners_loanPartners .LoanPartners_partnersCards {
        flex-direction: row;
        flex-wrap: wrap;
        /* justify-content: flex-start; */
    }
}

.LoanPartners_loanPartners .LoanPartners_card {
    display: flex;
    padding: 18px 24px;
    align-items: center;
    border-radius: 8px;
    background:linear-gradient(180deg, #eeefef 0%, #ffffff 100%, #ffffff 100%);
    margin-bottom: 12px;
    border: none;
}

@media only screen and (min-width: 768px) {
    .LoanPartners_loanPartners .LoanPartners_card {
        justify-content: space-between;
        width: 60%;
    }
}

@media only screen and (min-width: 991px) {
    .LoanPartners_loanPartners .LoanPartners_card {
        width: 30%;
        flex-direction: column;
        margin: 0 30px 30px 0;
        padding: 18px 12PX;
    }

    .LoanPartners_loanPartners .LoanPartners_card:last-child {
        margin-right: 0;
    }
}

@media only screen and (min-width: 1280px) {
    .LoanPartners_loanPartners .LoanPartners_card {
        width: 18%;
        margin: 0 24px 24px 0;
    }
}

.LoanPartners_loanPartners .LoanPartners_card img {
    width: 84px;
    height: 38px;
    object-fit: contain;
    margin-right: 1.4rem;
}

@media only screen and (min-width: 375px) {
    .LoanPartners_loanPartners .LoanPartners_card img {
        width: 90px;
    }
}

@media only screen and (min-width: 414px) {
    .LoanPartners_loanPartners .LoanPartners_card img {
        margin-right: 2rem;
        width: 104px;
        height: 45px;
    }
}

@media only screen and (min-width: 991px) {
    .LoanPartners_loanPartners .LoanPartners_card img {
        width: 124px;
        height: 48px;
        margin-bottom: 24px;
    }
}

@media only screen and (min-width: 1280px) {
    .LoanPartners_loanPartners .LoanPartners_card img {
        width: 140px;
        background-size: contain;
    }
}

.LoanPartners_loanPartners .LoanPartners_card a {
    padding: 6px 10px;
    /* font-size: 1.2rem; */
    /* font-weight: 500; */
    color: #fff;
    border-radius: 4px;
    min-width: 177px;
    background: #8c52ffba;
    display: flex;
    justify-content: center;
    flex: 1 1;
}

@media only screen and (min-width: 768px) {
    .LoanPartners_loanPartners .LoanPartners_card a {
        flex: none;
    }
}

@media only screen and (min-width: 991px) {
    .LoanPartners_loanPartners .LoanPartners_card a {
        font-size: 0.9rem;
        flex: 1 1;
        padding: 6px 24px;
        min-width: 227px;
    }
}

@media only screen and (min-width: 1280px) {
    .LoanPartners_loanPartners .LoanPartners_card a {
        padding: 6px 12px;
        min-width: 207px;
    }
}

.LoanPartners_loanPartners .LoanPartners_viewMore {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 16px;
    border: none;
}

.LoanPartners_loanPartners .LoanPartners_viewMore button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #222;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 150%;
    background: none;
    border: none;
    cursor: pointer;
}


.LoanPartners_loanPartners .LoanPartners_viewMore {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 16px;
    border: none;
}

.LoanPartners_loanPartners .LoanPartners_viewMore button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #222;
    font-size: 1rem;
    font-weight: 500;
    line-height: 150%;
    background: none;
    border: none;
    cursor: pointer;
}

.LoanPartners_loanPartners .LoanPartners_viewMore button:hover {
    color: #192b45;
}

.LoanPartners_card a{
    text-decoration: none;
}