* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    line-height: 1.6;
}

.apply-process {
    padding: 50px 20px;
    background-color: #f9f9f9;
}

.apply-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.section-title {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #333;
}

.section-description {
    font-size: 1.125rem;
    margin-bottom: 40px;
    color: #666;
    padding: 0 15px;
}

.process-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}

.process-step {
    flex: 1;
    max-width: 250px;
    min-width: 180px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    margin-bottom: 20px;
}

.process-step:hover {
    transform: translateY(-10px);
}

.process-step h4 {
    font-size: 1.25rem;
    margin-bottom: 10px;
    color: #007bff;
}

.process-step img {
    width: 70px;
    height: 70px;
    object-fit: contain;
    margin-bottom: 15px;
}

.process-step p {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .section-title {
        font-size: 1.8rem;
    }

    .section-description {
        font-size: 1.1rem;
    }

    .process-step h4 {
        font-size: 1.2rem;
    }

    .process-step img {
        width: 65px;
        height: 65px;
    }
}

@media (max-width: 768px) {
    .process-item {
        flex-direction: column;
        align-items: center;
    }

    .process-step {
        max-width: 100%;
    }

    .section-title {
        font-size: 1.6rem;
    }

    .section-description {
        font-size: 1rem;
    }

    .process-step h4 {
        font-size: 1.1rem;
    }

    .process-step img {
        width: 60px;
        height: 60px;
    }
}

@media (max-width: 480px) {
    .apply-process {
        padding: 30px 15px;
    }

    .section-title {
        font-size: 1.5rem;
    }

    .section-description {
        font-size: 0.95rem;
    }

    .process-step h4 {
        font-size: 1rem;
    }

    .process-step img {
        width: 55px;
        height: 55px;
    }

    .process-step p {
        font-size: 0.9rem;
    }
}
